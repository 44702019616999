<template>
	<b-modal id="leave-key-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" style="top:30%;">

		<template #modal-header> 
			<div></div>
			<h5>
				ARE YOU SURE?
			</h5> 
			<div></div>

    </template>


		<div class="d-flex text-center justify-content-around"> 
			<!-- <h4>
				<strong>IMPORTANT</strong> - Please keep and secure your Private Key NOW. You lose this key, you lose this wallet!
			</h4> -->
			<button class="btn btn-main" @click="confirm">YES</button>
			<button class="btn btn-main" @click="close">NO</button>
		</div>
		<LeaveKey/>
	</b-modal>
</template>

<script>

import qs from 'querystring' 
import LeaveKey from '@/components/modal/privateKey/ConfirmedLeavePrivateKey.vue'
import numeral from 'numeral' 
export default {
  name: 'ExportImportFund',
  components: {
	  LeaveKey,
  },
  data: function() {
      return  {
      	ei: {
      		state: 'export'
      	},
		isChecked: "Mobile",
      	successExport: false,
      	showError: false,
      	exportFile: {
      		amount: "",
      		secretKey: "",
      	},
      	importFile: {
      		amount: "",
      		file: null,
      	},
      	errors: {
      		amount: "",
      		secretKey: "",
      		file: "",
      		importSecretKey: ""
      	}
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		doneExport: function(){
   			this.successExport = false;
   			this.errors.amount = ""
   		},
   		toggleOption(selected) {
        this.ei.state = selected; 
  
      },

	  formatDate: function(time){
        return new Date(time).toLocaleString();
      },

   		confirm: function(){
   				
   			switch(this.init.privClose.state) {

   				 case 'closemodal':
   				 	window.location.reload();
   				 	break;

   				 	case 'showprivkey':

   						this.init.viewPrivKeyStep2.state  = 'confirm';
   				 		this.$bvModal.hide('leave-key-modal'); 
   				 		break;
   			}


   			//this.$bvModal.show('left-key-modal');
   		},

		 close: function(){
   				this.$bvModal.hide('leave-key-modal'); 
   		},

		   exit: function(){
   				this.$bvModal.hide('leave-key-modal'); 
				this.$emit("closeAll")
   		},
   		
   		formatAmount: function(num){

        return numeral(num).format('0,0.00000000');
      },
   	},
   	mounted(){
   		/* this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    
	    })*/
   		
 		},
}
</script>
 
<style scoped lang="scss">
	 
  button {
  	margin-top:1em;
  }

   .error {
  	color: red;
   }

   p {
	   margin-top:0px;
	   padding-top:0px;
   }

   h5 {
	   font-weight:bold;
	   margin-top:5px;
   }

   .form-control {
	   background-color: #ccc;
   }

  .email-or-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1em;

      .btn {
        color: #47525b;
        border: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        &.active {
          color: #d8962c;
          border-bottom: 1px solid #d8962c;
        }
      }
 
    }

	.btn-main {
   width:40%;
 }

	@media screen and (max-width:1000px) {
		div {
			font-size:0.86em;
		}

		div h5 {
			font-size:1em;
		}
	}
	
</style>
